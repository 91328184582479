// extracted by mini-css-extract-plugin
export var additionalPadding = "ThanksForDownload__additionalPadding__xe9D9";
export var button = "ThanksForDownload__button__vEuVG";
export var buttonContainer = "ThanksForDownload__buttonContainer__eTQ28";
export var buttonContainerHeading = "ThanksForDownload__buttonContainerHeading__fr7Nr";
export var buttonIcon = "ThanksForDownload__buttonIcon__mgdHe";
export var column = "ThanksForDownload__column__LSbdh";
export var formContainer = "ThanksForDownload__formContainer__eXyrr";
export var formTitle = "ThanksForDownload__formTitle__OBIFD";
export var layout = "ThanksForDownload__layout___dLzG";
export var row = "ThanksForDownload__row___IM4w";